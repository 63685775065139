export default {
  text: '#000000',
  primary: '#808d3e',
  secondary: '#808d3e',
  tertiary: 'lightgrey',
  background: 'white',
  backgroundSecondary: '#65594f',
  light: '#808d3e',
  dark: '#3A3335'
}
